import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Head from "../components/head"
const IndexPage = () => (
  <Layout>
    <Head
      title="サービス"
      description="株式会社アイテクスが提供しているサービスをご案内致します。"
    />
    <section className="hero is-halfheight">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">サービス</h1>
          <h2 className="subtitle">Our Service</h2>
        </div>
      </div>
    </section>
    <section className="section is-medium has-background-white-bis">
      <div className="container">
        <div className="columns is-variable is-5 is-vcentered">
          <div className="column is-one-quarter">
            <StaticImage
              src="../../content/assets/logo-telepaki.png"
              alt="テレパキ（TelePaKi）"
              placeholder="blurred"
              layout="fullWidth"
            />
          </div>
          <div className="column">
            <h1 className="title">テレパキ（TelePaKi）</h1>
            <h2 className="subtitle">
              当社が提供している、クラウド型タスク管理システムです。
            </h2>
            <div className="content">
              <p>
                「はい」か「いいえ」かタスクの結果を明確にします。
                <br />
                また、タスクの状況や担当者を明確にすることで、煩雑するタスクの管理をサポートします。
              </p>
            </div>
            <Link
              to="https://tele-paki.com/"
              target="_blank"
              className="button is-primary is-medium is-rounded is-fix-size-button mt-6"
            >
              サービスサイトへ
            </Link>
          </div>
        </div>
      </div>
    </section>
    <section className="section is-medium">
      <div className="container">
        <div className="columns is-variable is-5 is-vcentered">
          <div className="column is-one-quarter">
            <StaticImage
              src="../../content/assets/logo-website.png"
              alt="デザインおまかせプラン"
              placeholder="blurred"
              layout="fullWidth"
            />
          </div>
          <div className="column">
            <h1 className="title">デザインおまかせプラン</h1>
            <h2 className="subtitle">
              当社が提供している、ウェブサイトを”シンプル”かつ”低予算”で制作できるサービスです。
            </h2>
            <div className="content">
              <p>ウェブサイトの構成</p>
              <ul>
                <li>トップページ</li>
                <li>サブページ（5ページ）</li>
                <li>メールフォーム（1件）</li>
                <li>ブログ機能（1つ）</li>
              </ul>
            </div>
            <Link
              to="https://webplan.itxs.jp/"
              target="_blank"
              className="button is-primary is-medium is-rounded is-fix-size-button mt-6"
            >
              サービスサイトへ
            </Link>
          </div>
        </div>
      </div>
    </section>
    <section className="section is-medium has-background-white-bis">
      <div className="container">
        <div className="columns is-variable is-5 is-vcentered">
          <div className="column is-one-quarter">
            <StaticImage
              src="../../content/assets/logo-switch.png"
              alt="天文館のシェアオフィス「Switch」"
              placeholder="blurred"
              layout="fullWidth"
            />
          </div>
          <div className="column">
            <h1 className="title">Switch</h1>
            <h2 className="subtitle">
              天文館のシェアオフィス「Switch」を運営。
            </h2>
            <div className="content">
              <p>
                WiFiやロッカーはもちろん、複合機や防犯カメラ、電子レンジや冷蔵庫も完備しています。
              </p>
            </div>
            <Link
              to="https://bizswitch.jp/"
              target="_blank"
              className="button is-primary is-medium is-rounded is-fix-size-button mt-6"
            >
              サービスサイトへ
            </Link>
          </div>
        </div>
      </div>
    </section>
    <section className="section is-medium">
      <div className="container">
        <div className="columns is-variable is-5 is-vcentered">
          <div className="column is-one-quarter">
            <StaticImage
              src="../../content/assets/logo-pleasanter.png"
              alt="プリザンター"
              placeholder="blurred"
              layout="fullWidth"
            />
          </div>
          <div className="column">
            <h1 className="title">Pleasanter</h1>
            <h2 className="subtitle">
              当社は素早く簡単に業務アプリを作成できる「プリザンター」のパートナーです。
            </h2>
            <div className="content">
              <p>
                プリザンターとは、顧客管理や営業支援などの業務システムや、日報などの情報共有システムを最短30秒ノンプログラミングで作成可能。本当に欲しかった業務アプリが、フルスクラッチに比べて遥かに素早く簡単に作成できます。
              </p>
            </div>
            <Link
              to="https://pleasanter.itxs.biz/"
              target="_blank"
              className="button is-primary is-medium is-rounded is-fix-size-button mt-6"
            >
              サービスサイトへ
            </Link>
          </div>
        </div>
      </div>
    </section>
    <section className="section is-medium has-background-white-bis">
      <div className="container">
        <div className="columns is-variable is-5 is-vcentered">
          <div className="column is-one-quarter">
            <StaticImage
              src="../../content/assets/logo-tascal.png"
              alt="タスカル - Tascal -"
              placeholder="blurred"
              layout="fullWidth"
            />
          </div>
          <div className="column">
            <h1 className="title">タスカル - Tascal -</h1>
            <h2 className="subtitle">
              お子様のお手伝いをサポートするアプリです。
            </h2>
            <div className="content">
              <p>
                お手伝いを「たのしくないモノ」から「たのしいモノ」へ。
                <br />
                子どもとの関わりをもっと楽しくしてくれるアプリです。
              </p>
            </div>
            <Link
              to="https://tascal-39.com/"
              target="_blank"
              className="button is-primary is-medium is-rounded is-fix-size-button mt-6"
            >
              サービスサイトへ
            </Link>
          </div>
        </div>
      </div>
    </section>
    <section className="section is-medium">
      <div className="container">
        <div className="columns is-variable is-5 is-vcentered">
          <div className="column is-one-quarter">
            <StaticImage
              src="../../content/assets/logo-asagao.png"
              alt="asagao"
              placeholder="blurred"
              layout="fullWidth"
            />
          </div>
          <div className="column">
            <h1 className="title">asagao</h1>
            <h2 className="subtitle">
              AIが顔写真から体調を予測するアプリです。
            </h2>
            <div className="content">
              <p>
                顔写真を撮るだけであなたの健康をAIが測定し体調を数値化します。
                <br />
                asagaoを使うことで体調不良の兆候にいち早く気づけます。
              </p>
            </div>
            <Link
              to="https://asagao.life/"
              target="_blank"
              className="button is-primary is-medium is-rounded is-fix-size-button mt-6"
            >
              サービスサイトへ
            </Link>
          </div>
        </div>
      </div>
    </section>
    <section className="section is-medium has-background-white-bis">
      <div className="container">
        <div className="columns is-variable is-5 is-vcentered">
          <div className="column is-one-quarter">
            <StaticImage
              src="../../content/assets/logo-flea-market.png"
              alt="アイテクスフリマ"
              placeholder="blurred"
              layout="fullWidth"
            />
          </div>
          <div className="column">
            <h1 className="title">アイテクスフリマ</h1>
            <h2 className="subtitle">
              不定期開催しているフリーマーケットです。
            </h2>
            <div className="content">
              <p>
                掘り出し物があるかも！
                <br />
                不要になったIT製品を格安で販売することで、ムダを無くし少しでもゴミを減らし、SDGsの実現に向けて貢献していきます。
              </p>
            </div>
            <Link
              to="https://shop.itxs.biz/"
              target="_blank"
              className="button is-primary is-medium is-rounded is-fix-size-button mt-6"
            >
              サービスサイトへ
            </Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
export default IndexPage
